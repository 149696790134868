const axios = require("axios");
module.exports = {
  connectApi: (url) => {
    var url = url;
    const token = JSON.parse(localStorage.getItem("userAccess")).token;
    var params = `&tokenV2=${token}&from=am`;
    url = url + params;
    return axios.get(url);

    // var url = url;
    // const token = JSON.parse(localStorage.getItem("userAccess")).token;
    // const server = JSON.parse(localStorage.getItem("userAccess")).server;
    // var params = `&tokenV2=${token}&from=am`;
    // url = url + params;
    // let newUrl = url.replace(`https://sc${server}.clicklab.app`,'http://localhost:3000');
    // return axios.get(newUrl);
  },

  // connectApi: (url) => {
  //   const token = JSON.parse(localStorage.getItem("userAccess")).token;
  //   const server = JSON.parse(localStorage.getItem("userAccess")).server;
  //   const params = `&token=${token}&from=am`;
  //   // const header = {
  //   //   headers: {
  //   //     Authorization: token,
  //   //   },
  //   // };
  //   // let newUrl = url.replace(`&token=${token}`,'');
  //   let newUrl = url.replace(`sc${server}`,'dev-api');
  //   console.log(`sc${server}`);
  //   return axios.get(newUrl + params);
  // },
};
